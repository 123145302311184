import { graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import ArrowScroller from "../components/atoms/arrowScroller"
import Background from "../components/atoms/background"
import HeroImg from "../components/molecules/heroImage/heroImage"
import LandingText from "../components/molecules/landingtext/landingtext"
import Video from "../components/molecules/video/video"
import StopMotion from "../components/organisms/stopMotion/stopMotion"
import SEO from "../components/seo"
import useIsMobile from "../hooks/useIsMobile"
// import DesignPage from "./designs"

export const query = graphql`
  query IndexPage {
    page: wpPage(slug: { eq: "landing" }) {
      background {
        color: presets
        custom
      }
      landingFields {
        children: content {
          type: __typename
          ... on WpPage_Landingfields_Content_Textblock {
            text
          }
          ... on WpPage_Landingfields_Content_Image {
            size
            image {
              altText
              title
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          ... on WpPage_Landingfields_Content_VideoBlock {
            size
            video
          }
          ... on WpPage_Landingfields_Content_StopMotion {
            size
            speed
            frames {
              altText
              title
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }

    top: wpPage(slug: { eq: "designs" }) {
      id
      title
      slug
      acf: topDesignFields {
        data: topDesigns {
          link: llLink {
            target
            title
            url
          }
        }
      }
    }
    designs: allWpDesign {
      nodes {
        id
        title
        uri
        slug
        acf: designMetaFields {
          textLower
          textUpper
          thumbnail {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          meta_title: textUpper
          meta_desc: textLower
        }
          designFlexFields {
            slideroptions {
              autoplay
              transitionStyle
              transitionTime
            }
            slides {
              type: __typename
              ... on WpDesign_Designflexfields_Slides_Textblock {
                text
                textBackgroundColor {
                  custom
                  presets
                }
                sideDescription {
                  lower
                  upper
                }
              }
              ... on WpDesign_Designflexfields_Slides_VideoBlock {
                video
                textoverlay
                format {
                  boundaries
                  fieldGroupName
                  ratio {
                    fieldGroupName
                    height
                    width
                  }
                  mute
                  shadow
                  size
                }
                sideDescription {
                  lower
                  upper
                }
              }
              ... on WpDesign_Designflexfields_Slides_Image {
                fieldGroupName
                format {
                  boundaries
                  fieldGroupName
                  ratio {
                    fieldGroupName
                    height
                    width
                  }
                  shadow
                  size
                }
                sideDescription {
                  lower
                  upper
                }
                image {
                  sourceUrl
                  localFile {
                  ...AhoyImage
                  } 
                }
                textoverlay
              }
              ... on WpDesign_Designflexfields_Slides_StopMotion {
                fieldGroupName
                speed
                format {
                  boundaries
                  fieldGroupName
                  ratio {
                    fieldGroupName
                    height
                    width
                  }
                  shadow
                  size
                }
                frames {
                  sourceUrl
                  localFile {
              ...AhoyImage
            }
                }
                sideDescription {
                  fieldGroupName
                  lower
                  upper
                }
              }
            }
            popUp {
              text
              background {
                custom
                presets
              }
            }
          }
      }
    }
  }
`

const IndexPage = ({ data, location }) => {

  const { page, top, designs } = data
  const [ref, inView, entry] = useInView({ threshold: 0.9 })

  const [designData, setDesignData] = useState(null)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile || !page.landingFields.children) {
      console.log('navigate to design', location.pathname)
      navigate(`designs/`, { replace: true })
    }
  }, [])

  return (
    <>
      <SEO title="" />
      <Background
        preset={page.background.color}
        custom={page.background.custom}
      />
      <div id="landing" ref={ref}>
        {page.landingFields.children?.map((element, index) => {
          const format = {
            boundaries: element.size,
            shadow: true,
            mute: true,
            ratio: { width: 16, height: 9 },
            size: 100,
          }
          return {
            WpPage_Landingfields_Content_Image: (
              <HeroImg
                key={index}
                image={element.image?.localFile?.childImageSharp}
                format={format}
              />
            ),
            WpPage_Landingfields_Content_VideoBlock: (
              <Video
                key={index}
                iframe={element.video}
                inView={inView}
                format={format}
                landing={true}
              />
            ),
            WpPage_Landingfields_Content_Textblock: (
              <LandingText key={index} content={element.text} />
            ),
            WpPage_Landingfields_Content_StopMotion: (
              <StopMotion
                key={index}
                format={format}
                frames={element.frames}
                speed={element.speed}
              />
            ),
          }[element.type]
        })}
      </div>
      {/* <DesignPage
        location={location}
        navigate={navigate}
        data={{ top, designs }}
      /> */}
      <ArrowScroller />
    </>
  )
}

export default IndexPage
