import React, { useState, useEffect } from "react"

import { animateScroll as scroll, scroller } from "react-scroll"

const ArrowScroller = () => {
  const [height, setHeight] = useState(400)

  useEffect(() => {
    setHeight(window.innerHeight)
  })

  return (
    <button
      className="scroller"
      onClick={() =>
        scroll.scrollMore(height, { containerId: "container", smooth: true })
      }
      label="next"
    />
  )
}

export default ArrowScroller
